<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
      ref="alert" 
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form 
              ref="form" 
              @submit.prevent="submitForm"
              v-model="form.valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    v-model="form.generateRefCode"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('MEMBER_CARD.MANAGE.GENERATE_REF_CODE')"
                    filled
                    readonly
                    :rules="form.generateRefCodeRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="14"
                  md="7"
                >
                  <v-text-field
                    v-model="form.batchCode"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('MEMBER_CARD.SEARCH.BATCH_CODE')"
                    filled
                    readonly
                    :rules="form.batchCodeRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-menu
                    ref="expireMonthModal"
                    v-model="form.expireMonthModal"
                    :disabled="form.loading"
                    close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedExpireMonth"
                        :disabled="form.loading"
                        :label="$t('MEMBER_CARD.MANAGE.EXPIRE_MONTH')"
                        :rules="form.expireMonthRules"
                        hint="MM/YYYY format"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.expireMonth"
                      type="month"
                      locale="th"
                      :disabled="form.loading"
                      :min="minExpireMonth"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="default"
                        @click="form.expireMonthModal = false"
                        >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.expireMonth)"
                        >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                      >
                    </v-date-picker>     
                  </v-menu>
                </v-col>

              </v-row>
            <v-row>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-file-input 
                    accept="image/*"
                    show-size
                    v-model="form.cardFrontBase64"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_FRONT')"
                    :hint="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_FRONT_HINT')"
                    persistent-hint
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="5" v-if="form.cardPictureFrontUrl">
                  <v-img
                    :v-model="form.cardPictureFrontUrl"
                    :src="form.cardPictureFrontUrl"
                    :disabled="form.loading"
                    aspect-ratio="1.7"
                    contain
                  >
                  </v-img>
                </v-col>
                <v-col
                  cols="14"
                  md="5"
                >
                  <v-file-input 
                    accept="image/*"
                    show-size
                    v-model="form.cardBackBase64"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_BACK')"
                    :hint="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_BACK_HINT')"
                    persistent-hint
                  ></v-file-input>
                </v-col>
                <v-col cols="14" md="5" v-if="form.cardPictureBackUrl">
                  <v-img
                    :v-model="form.cardPictureBackUrl"
                    :src="form.cardPictureBackUrl"
                    :disabled="form.loading"
                    aspect-ratio="1.7"
                    contain
                  >
                  </v-img>
                </v-col>

                <!-- <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="form.inActiveStatus"
                    :disabled="form.loading"
                    :label="$t('SPLASH_SCREEN.ADD_EDIT.IN_ACTIVE_STATUS')"
                    required
                  ></v-checkbox>
                </v-col> -->
              </v-row>
              <v-row>
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon left>la-save</v-icon>
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </v-row>

              <v-dialog v-model="form.dialog" persistent max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{
                      $t("SPLASH_SCREEN.ADD_EDIT.SUCCESS_DIALOG_HEADER")
                    }}</v-card-title
                  >
                  <v-card-text>
                      {{ $t("SPLASH_SCREEN.ADD_EDIT.EDIT_SUCCESS_DIALOG_TEXT") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="closeDialog"
                      >{{ $t("SHARED.CLOSE_BUTTON") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="form.loading"
                persistent
                hide-overlay
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import moment from "moment";
export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      cardPictureFrontBase64: null,
      cardPictureBackBase64: null,
      form: {
        valid: true,
        loading: false,
        dialog: false,
        downloading: false,
        downloadingBatchId: null,
        errors: [],
        addRefCodeLoading: false,
        startDateFormatted: null,
        expireDateFormatted: null,
        expireMonth: null,
        expireMonthModal: false,
        expireMonthRules: [
          (v) => !!v || this.$t("MEMBER_CARD.MANAGE.EXPIRE_MONTH_REQUIRED"),
        ],
        generateRefCode: "",
        generateRefCodeRules: [
          (v) =>
            !!v || this.$t("MEMBER_CARD.MANAGE.GENERATE_REF_CODE_REQUIRED"),
          (v) =>
            (v && v.length >= 10) ||
            this.$t("MEMBER_CARD.MANAGE.GENERATE_REF_CODE_MIN_10_CHAR"),
        ],
        batchCode: "",
        batchCodeRules: [
          (v) =>
            !!v || this.$t("MEMBER_CARD.MANAGE.GENERATE_REF_CODE_REQUIRED"),
          (v) =>
            (v && v.length >= 10) ||
            this.$t("MEMBER_CARD.MANAGE.GENERATE_REF_CODE_MIN_10_CHAR"),
        ],
        cardFrontBase64: null,
        cardPictureFrontUrl: null,
        cardBackBase64: null,
        cardPictureBackUrl: null,
        picture: null,
        pictureType: [ "image/jpeg", "image/png" ],
        lotId: null,
      },
    };
  },
  computed: {
    title() {
      return this.$t("MENU.MEMBER_CARD.EDIT");
    },
    minExpireMonth() {
      return new Date().toISOString().substr(0, 7);
    },
    computedExpireMonth() {
      return this.formatDate(this.form.expireMonth);
    },
  },
  watch: {
    "form.expireMonth": {
      handler() {
        this.form.expireDateFormatted = this.formatDate(this.form.expireMonth);
      }
    }
  },
  methods: {
    formatDate(date) {
      //console.log("formatDate :" + date);
      if (!date) return null;

      const [month, day] = date.split("-");
      return `${day}/${month}`;
    },
    parseDate(date) {
      //console.log("parseDate :" + date);
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")} HH:mm:ss`;
    },
    getFileBase64(file) {
      console.log('3')
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve({
            url: reader.result,
            base64: reader.result.split(",")[1],
            contentType: file.name.split(".")[1],
            size: file.size,
          });
        };
        reader.readAsDataURL(file);
      });
    },
    getImageMetadata(file){
      return new Promise(reslove => {
        let image = new Image();
        image.onload = () => {
          //console.log(image);
          reslove({
            w: image.width,
            h: image.height,
          });
        };
        image.src = file;
      });
    },
    submitForm() {
      var chk = this.$refs.form.validate();
      this.form.errors = [];

      if (this.form.cardFrontBase64) {
        if (!this.form.pictureType.includes(this.form.cardFrontBase64.type)) {
          this.form.errors.push(
            "กรุณาเลือกรูป Format .jpg, .jpeg, .png เท่านั้น"
          );
        }

        this.getFileBase64(this.form.cardFrontBase64).then(file => {
            let mb = 2 * 1024 * 1024;
            if (file.size > mb)
              this.form.errors.push(
                "กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"
              );

            if (this.form.errors.length > 0) {
              this.$vuetify.goTo(this.$refs.alert, {
                duration: 1000,
                easing: "easeInOutCubic",
                offset: -20
              });
              chk = false;
            }

            if (this.form.cardBackBase64) {
              this.getFileBase64(this.form.cardBackBase64).then(fileBack => {
                let mb = 2 * 1024 * 1024;
                if (fileBack.size > mb)
                  this.form.errors.push(
                    "กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"
                  );

                if (this.form.errors.length > 0) {
                  this.$vuetify.goTo(this.$refs.alert, {
                    duration: 1000,
                    easing: "easeInOutCubic",
                    offset: -20
                  });
                  chk = false;
                }
                if (chk) {
                  this.postDataToApi(file, fileBack);
                }
              });
            }
            if (chk && !this.form.cardBackBase64) {
              this.postDataToApi(file);
            }
        });
      } else if (!this.form.cardFrontBase64 && this.form.cardBackBase64) {
        this.getFileBase64(this.form.cardBackBase64).then(fileBack => {
          let mb = 2 * 1024 * 1024;
          if (fileBack.size > mb)
            this.form.errors.push(
              "กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"
            );

          if (this.form.errors.length > 0) {
            this.$vuetify.goTo(this.$refs.alert, {
              duration: 1000,
              easing: "easeInOutCubic",
              offset: -20
            });
            chk = false;
          }
          if (chk) {
            this.postDataToApi(null, fileBack);
          }
        });
      } else {
        if (this.form.errors.length > 0) {
          this.$vuetify.goTo(this.$refs.alert, {
            duration: 1000,
            easing: "easeInOutCubic",
            offset: -20,
          });
          chk = false;
        }

        if (chk) {
          this.postDataToApi();
        }
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    postDataToApi(file , fileBack) {
      this.form.loading = true;
      this.form.errors = [];
      
      ApiService.setHeader();
      ApiService.put("/Api/MemberCards/Edit", 
      {
        lotId: this.form.lotId,
        batchCode: this.form.batchCode,
        expireDateString: moment(this.form.expireMonth, "YYYY-MM HH:mm:ss")
          .endOf("month").format("DD/MM/YYYY HH:mm:ss"),
        generateRefCode: this.form.generateRefCode,
        cardFrontBase64: file == null ? null : file.base64,
        cardBackBase64: fileBack == null ? null : fileBack.base64,
        cardFrontContentType: file == null ? null : file.contentType,
        cardBackContentType: fileBack == null ? null : fileBack.contentType,
      })
      .then(({ data }) => {
        if (data.status) {
          // close dialog
          this.form.dialog = true;
        } else {
          this.form.dialog = false;
          this.form.loading = false;
          this.form.errors.push(!!data.message || "Unknow error occurs");
          this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
        }
      })
      .catch(({ response }) => {
        if (response.data) {
          this.form.errors.push(response.data.message);
        } else {
          this.form.errors.push("Unknow error occurs");
        }
        this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
        this.form.dialog = false;
        this.form.loading = false;
      });
    },
    closeDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "ManageMemberCards" });
    },
    getDataFromApi(id) {
      this.form.loading = true;
      return new Promise(() => {
        ApiService.setHeader();
        ApiService.get("/Api/MemberCards", id)
          .then(({ data }) => {
            let exp = data.expireDateString.split(" ");

            this.form.lotId = data.lotId
            this.form.batchCode = data.batchCode;
            this.form.expireDateFormatted = exp[0];
            this.form.expireMonth = this.parseDate(this.form.expireDateFormatted);
            this.form.generateRefCode = data.generateRefCode;
            // this.form.cardPictureFront = data.cardPictureFront;
            // this.form.cardPictureBack = data.cardPictureBack;
            this.form.cardPictureFrontUrl = data.downloadFileFrontByteUrl;
            this.form.cardPictureBackUrl = data.downloadFileBackByteUrl;
          })
          .finally(() => {
            this.form.loading = false;
          });
      },
      );
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.MEMBER_CARD.SECTION"), route: "/MemberCards" },
      { title: this.$t("MENU.MEMBER_CARD.EDIT") },
    ]);

    this.getDataFromApi(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
